.modal.outcome {
    
    .modal-content {
        width: 404px;
        max-width: 100%;
    }
    .modal-content-wrapper {
        min-height: 183px;
        // width: auto;
    }
}