.helpful-resources-content {

    .helping-hand-image-container {
        width: 100% /* or a specific width */;
        position: relative;
        max-height: 421px;

        &::before {
          content: "";
          display: block;
          padding-top: calc(100% / 1.87); /* Aspect ratio */
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
      

    .interactive-button {
        position: relative;
        width: 100%;
        max-width: 300px;
        background-color: #333333; // #6A1B2A;
        border: 10px solid #333333;
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
        height: 100px;

        &:hover div {
            top: 0;
            bottom: 0;
            transition: top 0.3s, bottom 0.3s, color 0.3s, background-color 0.3s;
        }

        &:active div {
            background-color: #6A1B2A;
            color: #d9d9d9;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #6A1B2A;
            border-radius: 10px;
        }

        div {
            z-index: 1;
            position: absolute;
            top: -15px;
            left: 0;
            right: 0;
            bottom: 15px;
            background-color: #C62828;
            color: #F7F7F7;
            font-size: 1.5rem;
            font-weight: 700;
            text-align: center;
            border-radius: 10px;
            transition: top 0.2s, bottom 0.2s, color 0.3s, background-color 0.3s;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
