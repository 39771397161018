@tailwind base;
@tailwind components;
@tailwind utilities;
/* "We have two brand colors that we use. The red fat more than the salmon" 
    Red = #7D1E2C
    Salmon = #34A388

    "The font we use for our logo is: NIOBIUM"  
*/
:root {
  --font-color-1: #333333;
  --font-color-2: #D9D9D9;
  --font-color-3: #F7F7F7;
  --font-color-4: #6B7280;
  --font-color-5: #9CA3AF;
  --font-color-6: #7D1E2C;
  --font-active-color-1: #3F51B5;
  --font-active-color-2: #FA8072;
  --background-color-1: #F7F7F7;
  --background-color-2: #6A1B2A;
  --dropdown-background-color: #7D1E2C;
  --border-color-1: #C62828;
  --border-color-2: #C62828;
  --border-color-3: #D9D9D9;
  --button-background-color: #3F51B5;
  --button-text-color: #F7F7F7;
  --button-hover-background-color: rgb(20, 83, 137);
  --scrollbar-width: 12px;
  --scrollbar-bg: #7D1E2C;
  --scrollbar-thumb: #D9D9D9;
  --font-family-1: "Open Sans", sans-serif;
  --font-family-2: "Niobium", "Open Sans", sans-serif;
  --font-family-3: "Oswald", sans-serif;
}

@font-face {
  font-family: "Niobium";
  src: url("./assets/fonts/niobium/NIOBRG.TTF") format("truetype");
}
@font-face {
  font-family: "Impact";
  src: url("./assets/fonts/impact/impact.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/oswald/Oswald-VariableFont_wght.ttf") format("truetype");
}
.header-wrapper {
  background-color: var(--background-color-2);
  border-bottom: 2px solid var(--border-color-2);
  color: var(--font-color-2);
}
.header-wrapper::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--background-color-2);
  z-index: -1;
}
.header nav.open {
  max-height: var(--page-height);
}
.header nav ul li {
  position: relative;
}
.header nav ul li button, .header nav ul li a {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
}
.header nav ul li button:hover, .header nav ul li a:hover {
  color: var(--font-active-color-2);
}
.header nav ul li button:not(:hover), .header nav ul li a:not(:hover) {
  color: var(--font-color-3);
}
.header nav ul li button svg, .header nav ul li a svg {
  margin: auto auto;
}
.header .dropdown-nav {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  background-color: var(--dropdown-background-color);
}
.header .dropdown-nav ul {
  font-weight: 400;
  color: var(--font-color-2);
}
.header .dropdown:not(.open) .header .dropdown-toggle:focus {
  color: var(--font-color-3);
}
.header .dropdown.open .dropdown-toggle, .header .dropdown.open > a {
  color: var(--font-active-color-2);
}
.header .dropdown.open .dropdown-toggle svg, .header .dropdown.open > a svg {
  transform: rotate(180deg);
}
.header .dropdown.open .dropdown-nav {
  z-index: 1;
  max-height: var(--page-height);
}
@media (max-width: 1023px) {
  .header .nav-menu {
    position: absolute;
    top: var(--header-height);
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  .header .nav-menu > ul {
    padding: 0px 32px;
    padding-bottom: 32px;
    background-color: var(--dropdown-background-color);
    border-bottom: 2px solid var(--border-color-2);
    max-height: var(--page-height);
    overflow-y: auto;
  }
  .header .nav-menu > ul li {
    border-bottom: 1px solid var(--border-color-2);
  }
  .header .nav-menu > ul li button, .header .nav-menu > ul li a {
    width: 100%;
    padding: 12px 8px 4px 8px;
  }
  .header .nav-menu > ul li button svg, .header .nav-menu > ul li a svg {
    margin: auto 0;
  }
  .header .nav-menu .dropdown-nav {
    background-color: var(--background-color-2);
  }
  .header .nav-menu .dropdown-nav ul li {
    margin: 0px 32px;
  }
  .header .nav-menu .dropdown-nav ul li button, .header .nav-menu .dropdown-nav ul li a {
    padding: 8px;
  }
  .header .nav-menu .dropdown-nav ul li:last-of-type {
    border-bottom: none;
  }
}
@media (min-width: 1024px) {
  .header-wrapper::before {
    border-bottom: 2px solid var(--border-color-2);
  }
  .header .nav-menu > ul > li .dropdown-toggle, .header .nav-menu > ul > li .nav-link {
    height: 58px;
    padding: 20px 10px 10px 10px;
  }
  .header .nav-menu > ul > li .dropdown .dropdown-toggle::before, .header .nav-menu > ul > li .dropdown .dropdown-toggle::after, .header .nav-menu > ul > li .nav-link::before, .header .nav-menu > ul > li .nav-link::after {
    content: "";
    position: absolute;
    height: 2px;
    background: var(--font-color-2);
    transition: all 0.3s ease;
    bottom: 10px;
  }
  .header .nav-menu > ul > li .dropdown .dropdown-toggle::before, .header .nav-menu > ul > li .nav-link::before {
    left: 5px;
    width: 0%;
  }
  .header .nav-menu > ul > li .dropdown .dropdown-toggle::after, .header .nav-menu > ul > li .nav-link::after {
    right: 5px;
    width: 0%;
  }
  .header .nav-menu > ul > li .dropdown.open .dropdown-toggle::before, .header .nav-menu > ul > li .dropdown.open .dropdown-toggle::after, .header .nav-menu > ul > li .nav-link:hover::before, .header .nav-menu > ul > li .nav-link:hover::after, .header .nav-menu > ul > li .nav-link:focus::before, .header .nav-menu > ul > li .nav-link:focus::after {
    width: calc(50% - 5px);
    background: var(--font-active-color-2);
  }
  .header .dropdown-nav {
    position: absolute;
    top: 58px;
    right: 0;
    width: -moz-max-content;
    width: max-content;
    border-radius: 0 0 10px 10px;
  }
  .header .dropdown-nav ul {
    border: 2px solid var(--border-color-2);
  }
  .header .dropdown-nav ul li button, .header .dropdown-nav ul li a {
    margin: 0 8px;
    padding: 12px 8px;
  }
  .header .dropdown-nav ul li:not(:last-of-type) button, .header .dropdown-nav ul li:not(:last-of-type) a {
    border-bottom: 1px solid var(--border-color-2);
  }
}

.footer {
  background-color: var(--background-color-2);
  color: var(--font-color-2);
  border-top: 2px solid var(--border-color-2);
}
.footer hr {
  border-top-width: 2px;
  border-color: var(--border-color-2);
}
.footer li {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* frontend/src/components/header/HamburgerCross.css */
.hamburger-container {
  color: var(--font-color-3);
}
.hamburger-container.active {
  color: var(--font-active-color-2);
}
.hamburger-container.active .hamburger-icon-bar1, .hamburger-container.active .hamburger-icon-bar2, .hamburger-container.active .hamburger-icon-bar3 {
  background-color: var(--font-active-color-2);
}
.hamburger-container.active .hamburger-icon .hamburger-icon-bar1 {
  transform: translate(0, 10px) rotate(-45deg);
}
.hamburger-container.active .hamburger-icon .hamburger-icon-bar2 {
  transform: translateX(12px);
  width: 0px;
}
.hamburger-container.active .hamburger-icon .hamburger-icon-bar3 {
  transform: translate(0, -10px) rotate(45deg);
}
.hamburger-container .hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.hamburger-container .hamburger-icon-bar1, .hamburger-container .hamburger-icon-bar2, .hamburger-container .hamburger-icon-bar3 {
  width: 28px;
  height: 4px;
  background-color: var(--font-color-3);
  transition: 0.3s;
  position: relative;
  border-radius: 10px;
}
.hamburger-container .hamburger-icon-bar1 {
  left: 0px;
}
.hamburger-container .hamburger-icon-bar2 {
  left: 0px;
}
.hamburger-container .hamburger-icon-bar3 {
  left: 0px;
}

.page-wrapper {
  margin-top: var(--header-height);
  min-height: var(--page-height);
}

.page-content {
  padding: 75px 0;
  margin: 0 auto;
  width: 95%;
}
.page-content section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.page-content section ul, .page-content section ol {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 769px) {
  .page-content {
    width: 90%;
  }
}
@media (min-width: 1025px) {
  .page-content {
    width: 80%;
  }
}

.video-container {
  position: relative;
  border-color: var(--border-color-1);
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.spinner-circle {
  width: 30px;
  height: 30px;
  border: 5px solid #D9D9D9;
  border-top-color: var(--font-active-color-1);
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}
.spinner-loading {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.2rem;
  font-weight: 600;
}
.spinner-message {
  color: #555555;
  text-align: center;
  width: 100%;
}

.banner-image {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  background-repeat: no-repeat;
  pointer-events: none;
  transition: filter 0.5s;
}
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  background: repeat rgb(0, 0, 0);
}
.banner-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.big-calendar {
  position: relative;
}
.big-calendar .rbc-event {
  animation: fade-in 0.3s ease-in-out;
}
.big-calendar .spinner {
  position: absolute;
  top: 122px;
  left: 0;
  bottom: 0;
  right: 0;
  transform: none;
  border-radius: 4px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .big-calendar .spinner {
    top: 71px;
  }
}
.big-calendar .spinner-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--font-color-1);
  z-index: 10;
}
.big-calendar .spinner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10;
}

.calendar-event-description *, .calendar-event-details *, .calendar-event-links * {
  font-size: 16px !important;
}
.calendar-event-description p {
  margin-bottom: 10px;
}
.calendar-event-description ul {
  margin-left: 2rem;
  margin-bottom: 10px;
}
.calendar-event-description li, .calendar-event-description li b, .calendar-event-description li i {
  list-style-type: disc;
  font-size: 14px !important;
}

.modal.re-captcha .modal-content {
  width: auto;
}
.modal.re-captcha .modal-content-wrapper {
  min-height: 183px;
}

.modal.outcome .modal-content {
  width: 404px;
  max-width: 100%;
}
.modal.outcome .modal-content-wrapper {
  min-height: 183px;
}

.home-content {
  padding: 0px;
  width: 100%;
  max-width: none;
}
.home-content .landing {
  height: var(--page-height);
}
.home-content .landing-welcome-text {
  z-index: 1;
  color: #333333;
  border-color: var(--border-color-1);
}

.bulletin-content section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 640px) {
  .womens-sign-up-content .form-row-radio {
    grid-template-columns: 1fr 2fr;
  }
}

.membership-content ul {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.helpful-resources-content .helping-hand-image-container {
  width: 100%;
  position: relative;
  max-height: 421px;
}
.helpful-resources-content .helping-hand-image-container::before {
  content: "";
  display: block;
  padding-top: 53.4759358289%; /* Aspect ratio */
}
.helpful-resources-content .helping-hand-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.helpful-resources-content .interactive-button {
  position: relative;
  width: 100%;
  max-width: 300px;
  background-color: #333333;
  border: 10px solid #333333;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  height: 100px;
}
.helpful-resources-content .interactive-button:hover div {
  top: 0;
  bottom: 0;
  transition: top 0.3s, bottom 0.3s, color 0.3s, background-color 0.3s;
}
.helpful-resources-content .interactive-button:active div {
  background-color: #6A1B2A;
  color: #d9d9d9;
}
.helpful-resources-content .interactive-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #6A1B2A;
  border-radius: 10px;
}
.helpful-resources-content .interactive-button div {
  z-index: 1;
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  bottom: 15px;
  background-color: #C62828;
  color: #F7F7F7;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  border-radius: 10px;
  transition: top 0.2s, bottom 0.2s, color 0.3s, background-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.local-resources-content a {
  color: var(--font-active-color-1);
}
.local-resources-content ul {
  gap: 0.5rem !important;
}
.local-resources-content .logo-image-container {
  position: relative;
  overflow: hidden;
  max-height: 155.58px;
  height: calc(151.89px + 0.307 * (100vw - 545px));
}

:root {
  --header-height: 60px;
  --page-height: calc(100svh - var(--header-height));
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: var(--font-family-1);
}

body {
  background-color: var(--background-color-2);
}

.App {
  background-color: #F7F7F7;
  background-image: url("./assets/images/textures/handmade-paper.png");
  color: #333333;
}

*, body {
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}
*::-webkit-scrollbar, body::-webkit-scrollbar {
  width: var(--scrollbar-width);
}
*::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
}
*::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 6px;
  border: 3px solid var(--scrollbar-bg);
}

h1, h1 p, h1 span {
  font-family: var(--font-family-2);
}

h2 {
  font-family: var(--font-family-3);
  color: var(--font-color-6);
  font-size: 24px;
  font-weight: 600;
}

p, a, button {
  font-size: 16px;
}

@media (min-width: 768px) {
  h2 {
    font-size: 30px;
  }
  main p, main a, main button {
    font-size: 18px;
  }
}
@media (min-width: 1280px) {
  h2 {
    font-size: 36px;
  }
  main p, main a, main button {
    font-size: 20px;
  }
}
a {
  text-decoration: none;
  cursor: pointer;
}

.header a:hover, .header a.active, .footer a:hover, .footer a.active {
  color: var(--font-active-color-2);
}

.header a.isActive {
  color: var(--font-active-color-2) !important;
}

main a {
  color: var(--font-active-color-1);
}
main a:hover, main a.active {
  text-decoration: underline;
}

hr {
  width: 100%;
}

main hr {
  border-color: var(--border-color-3);
}

header hr {
  border-color: var(--border-color-2);
}

button:hover, button.active {
  color: var(--font-active-color-1);
}

button, a {
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
button.disabled, a.disabled {
  color: gray !important;
  pointer-events: none;
  cursor: not-allowed;
}

form {
  border: 2px solid var(--border-color-1);
  border-radius: 0.5rem;
  max-width: 678px;
  margin: 0 auto;
}
form .form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
form input, form textarea {
  padding: 0.5rem;
  border-radius: 0.5rem;
}
form input:focus, form textarea:focus {
  outline: none;
  z-index: 2;
}
form .radio, form .checkbox {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}
form .form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
form .form-group > label, form .form-group legend {
  color: var(--font-color-4);
}
form .form-group-text-input {
  position: relative;
  border: 1px solid #6B7280;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  margin-bottom: 1rem;
}
form .form-group-text-input label {
  content: attr(htmlFor);
  position: absolute;
  top: 8px;
  left: 8px;
  transition: 0.3s ease-in-out;
  color: var(--font-color-4);
  pointer-events: none;
  z-index: 2;
}
form .form-group-text-input input, form .form-group-text-input textarea {
  background-color: transparent;
}
form .form-group-text-input input:focus + label,
form .form-group-text-input textarea:focus + label, form .form-group-text-input.is-active label {
  top: -18px;
  font-size: 12px;
}
form .form-group-text-input .form-group-error-text {
  position: absolute;
  bottom: -24px;
  left: 8px;
}
form .form-group.has-error {
  border-color: #f56565;
}
form .form-group.is-active:not(.has-error) {
  border-color: var(--font-color-1);
}
form .form-group.is-active:not(.has-error) label {
  color: var(--font-color-1);
}
form .form-group-error-text {
  color: #f56565;
  animation: fade-in 0.5s; /* Assuming you have a fade-in animation defined */
  font-size: 0.875rem;
  font-weight: 400;
}
form .form-row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}
@media (min-width: 640px) {
  form .form-row {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  animation: fade-in 0.2s ease-in-out;
}
.modal::-webkit-scrollbar {
  z-index: 100;
}
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-content {
  background-color: var(--background-color-1);
  border: 2px solid var(--border-color-1);
  border-radius: 1rem;
  width: 100%;
  max-width: 512px;
  max-height: 90vh;
  overflow: hidden;
}
.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  overflow: auto;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.fade-in {
  animation: fade-in 0.3s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=index.css.map */