@tailwind base;
@tailwind components;
@tailwind utilities;

// Variables
@import './_theme.scss';

// Components
@import './components/header/_header.scss';
@import './components/footer/_footer.scss';
@import './components/hamburgerMenu/_hamburgerMenu.scss';
@import './components/pageWrapper/_pageWrapper.scss';
@import './components/videoContainer/_videoContainer.scss';
@import './components/spinner/_spinner.scss';
@import './components/banner/_banner.scss';
@import './components/bigCalendar/_bigCalendar.scss';

// Modals
@import './modals/calendarEvent/_calendarEvent.scss';
@import './modals/reCaptchaModal/_reCaptchaModal.scss';
@import './modals/outcomeModal/_outcomeModal.scss';


// Pages
@import './pages/home/_home.scss'; // About Us
@import './pages/aboutUs/beliefs/_beliefs.scss';
@import './pages/aboutUs/whatToExpect/_whatToExpect.scss';
@import './pages/aboutUs/vision/_vision.scss';
@import './pages/aboutUs/leadership/_leadership.scss';
@import './pages/aboutUs/elder/_elder.scss';

@import './pages/eventInfo/bulletin/_bulletin.scss'; // Event Info
@import './pages/eventInfo/womensSignUp/_womensSignUp.scss';

@import './pages/giving/stocksAndCrypto/_stocksAndCrypto.scss'; // Giving

@import './pages/connect/membership/_membership.scss'; // Connect
@import './pages/connect/kids/_kids.scss';
@import './pages/connect/prayerRequests/_prayerRequests.scss';

@import './pages/care/helpfulResources/_helpfulResources.scss'; // Care
@import './pages/care/localResources/_localResources.scss'; // Care


:root {
    --header-height: 60px;
    --page-height: calc(100svh - var(--header-height));
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    scroll-behavior: smooth;

    font-family: var(--font-family-1); // 'Open Sans', sans-serif;
}

body {
    background-color: var(--background-color-2); // #F7F7F7;
}

.App {
    background-color: #F7F7F7;
    // background-image: url('./assets/images/textures/60-lines.png');
    // background-image: url('./assets/images/textures/az-subtle.png');

    // background-image: url('./assets/images/textures/binding-light.png');

    // background-image: url('./assets/images/textures/clean-gray-paper.png');
    // background-image: url('./assets/images/textures/cream-paper.png');

    background-image: url('./assets/images/textures/handmade-paper.png');

    // background-image: url('./assets/images/textures/light-paper-fibers.png');
    // background-image: url('./assets/images/textures/natural-paper.png');
    // background-image: url('./assets/images/textures/textured-paper.png');
    // background-image: url('./assets/images/textures/white-paperboard.png');
    color: #333333;
}


// Scrollbar
*, body {
    // scrollbar-width: var(--scrollbar-width);
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
  
    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
      // border-radius: 10px !important;
    }
    
    &::-webkit-scrollbar-track {
      background: var(--scrollbar-bg);
      // border-radius: 10px !important;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb);
      border-radius: 6px;
      border: 3px solid var(--scrollbar-bg);
    }
}

// Fonts
h1 {
    &, p, span {
        font-family: var(--font-family-2); // 'Niobium', 'Open Sans', sans-serif;
    }
}

h2 {
    font-family: var(--font-family-3); // 'Impact', sans-serif;
    color: var(--font-color-6);

    font-size: 24px;
    font-weight: 600;

    // @media (min-width: 768px) {
}
    
p, a, button {
    font-size: 16px;
}

@media (min-width: 768px) {
    h2 {font-size: 30px}

    main {
        p, a, button {font-size: 18px}
    }
}
@media (min-width: 1280px) {
    h2 {font-size: 36px}

    main {
        p, a, button {font-size: 20px}
    }
}

// Links
a {
    text-decoration: none;
    cursor: pointer;

    
}

.header a, .footer a {
    // color: var(--font-active-color-2);

    &:hover, &.active {
        // background-color: #D4AF37;
        color: var(--font-active-color-2);
    }
}

.header a {
    &.isActive {
        color: var(--font-active-color-2) !important;
        // pointer-events: none;
        // font-weight: 600;
    }
}

main a {
    color: var(--font-active-color-1);

    &:hover, &.active {
        // background-color: #D4AF37;
        // color: var(--font-active-color-2);
        text-decoration: underline;
    }
}

// HR
hr {
    width: 100%;
}
main hr { border-color: var(--border-color-3); }
header hr { border-color: var(--border-color-2); }

// Buttons
button {

    &:hover, &.active {
        // background-color: #D4AF37;
        color: var(--font-active-color-1);
    }    
}

// Buttons + Links
button, a {
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &.disabled {
        color: gray !important;
        pointer-events: none;
        cursor: not-allowed;
    }
}

// Form stuff
form {
    border: 2px solid var(--border-color-1);
    border-radius: 0.5rem;
    max-width: 678px;
    margin: 0 auto;

    .form-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem; // 1.5rem;
    }

    input, textarea {
        padding: 0.5rem;
        // margin: 0.5rem 0;
        border-radius: 0.5rem;
    
        &:focus {
            outline: none;
            z-index: 2;
        }
    }

    .radio, .checkbox {
        display: flex;
        align-items: baseline; // center;
        gap: 0.5rem;
    }

    .form-group {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        & > label, legend {
            color: var(--font-color-4);
        }
    }
    
    .form-group-text-input {
        position: relative;
        border: 1px solid #6B7280;
        border-radius: 0.5rem;
        transition: all 0.2s ease-in-out;
        margin-bottom: 1rem;

        label {
            content: attr(htmlFor); // Use the name attribute for content
            position: absolute;
            top: 8px; // 16px;
            left: 8px;
            transition: .3s ease-in-out;
            color: var(--font-color-4); // #999;
            pointer-events: none;
            z-index: 2;
        }

        input, textarea {
            background-color: transparent;
        }

        // input:focus + label,
        // textarea:focus + label {
        //     color: var(--font-color-4); // #fff;
        // }

        input:focus + label,
        textarea:focus + label,
        &.is-active label {
            top: -18px; // -12px;
            font-size: 12px;
            // color: inherit;
        }

        .form-group-error-text {
            position: absolute;
            bottom: -24px; // -28px;
            left: 8px;
        }

    }
    .form-group.has-error {
        border-color: #f56565;
        // label {color: #f56565 !important} // text-red-500
    }

    .form-group.is-active:not(.has-error) {
        border-color: var(--font-color-1);
        label {color: var(--font-color-1)} // text-white
    }

    .form-group-error-text {
        color: #f56565; // #f00; /* Equivalent to text-red-500 */
        // position: absolute;
        // bottom: -28px;
        // left: 8px;
        animation: fade-in 0.5s; /* Assuming you have a fade-in animation defined */
        font-size: 0.875rem;
        font-weight: 400; // 600;
    }
    
    .form-row {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    
    // @media (min-width: 769px) {
    @media (min-width: 640px) {
        .form-row {
            grid-template-columns: 1fr 1fr;
            gap: 2rem; // 4rem;
        }
    }
}

.modal {
    // overflow-y: scroll;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    animation: fade-in 0.2s ease-in-out;

    &::-webkit-scrollbar {
        z-index: 100;
    }
    
    &-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        background-color: rgba(0, 0, 0, 0.5);
    }

    &-content {
        background-color: var(--background-color-1);
        border: 2px solid var(--border-color-1);
        border-radius: 1rem;
        
        width: 100%;
        max-width: 512px;
        max-height: 90vh;
        overflow: hidden;
        
        
        &-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            overflow: auto;
        }
    }
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.fade-in {
    animation: fade-in 0.3s ease-in-out;
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }