.calendar-event {
    
    &-description, &-details, &-links {
        * {
            font-size: 16px !important;
        }
    }

    &-description {

        p {
            margin-bottom: 10px;
        }

        ul {
            margin-left: 2rem;
            margin-bottom: 10px;
        }
        
        li, li b, li i {
            list-style-type: disc;
            font-size: 14px !important;
        }
    }
}