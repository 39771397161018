.page-wrapper {
    margin-top: var(--header-height);
    min-height: var(--page-height);
}

.page-content {
    padding: 75px 0;
    margin: 0 auto;

    width: 95%;
    // max-width: 1090px;

    // @media (min-width: 769px) {
    //     width: 90%;
    // }

    section {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        ul, ol {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        // li {
        //     display: flex;
        //     flex-direction: column;
        //     gap: 0.5rem;
        // }
    }

    @media (min-width: 769px) {
        width: 90%;
    }
    
    @media (min-width: 1025px) {
        width: 80%;
    }
}