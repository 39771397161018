.big-calendar {
    position: relative;

    .rbc-event {
        animation: fade-in 0.3s ease-in-out;
    }

    .spinner {
        position: absolute;
        top: 122px; // 98px;
        left: 0;
        bottom: 0;
        right: 0;
        transform: none;

        border-radius: 4px;
        overflow: hidden;

        @media (min-width: 768px) {
            top: 71px; // 44px;
        }

        &-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--font-color-1);
            z-index: 10;
            // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            background-color: rgba(255, 255, 255, 0.5);
            z-index: 10;
        }
    }

}