.banner {
    &-image {
        position: absolute;

        width: 100%;

        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        object-fit: cover;
        // object-position: 0% 60%;
        background-repeat: no-repeat;
        pointer-events: none;

        transition: filter 0.5s;

        // filter: blur(2px);
        // scale: 1.05;

    }

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        opacity: 0.25;
        background: repeat rgb(0, 0, 0);
    }

    &-text {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

        // background: inherit;
        // background-clip: text;
        // color: transparent;
        // filter: invert(1) grayscale(1) contrast(9);

        // color: white;
        // mix-blend-mode: difference;
    }

    // &-text {
    //     h1 {
    //         font-size: 4rem;
    //     }
    // }
}