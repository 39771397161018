/* frontend/src/components/header/HamburgerCross.css */

.hamburger-container {
  color: var(--font-color-3);

  &.active {
    color: var(--font-active-color-2);

    .hamburger-icon {
      // transform: translateX(-200px); // -130px

      &-bar1, &-bar2, &-bar3 {
        background-color: var(--font-active-color-2);
      }
  
      .hamburger-icon-bar1 {
        transform: translate(0, 10px) rotate(-45deg);
      }
      .hamburger-icon-bar2 {
        // opacity: 0;
        transform: translateX(12px);
        width: 0px;
      }
      .hamburger-icon-bar3 {
        transform: translate(0, -10px) rotate(45deg);
      }
    }
  }

  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  
    // transition: 0.2s ease-in-out transform, 0.2s ease-in-out opacity;
  
    &-bar1, &-bar2, &-bar3 {
      width: 28px;
      height: 4px;
      background-color: var(--font-color-3);
      transition: 0.3s;
      position: relative;
      border-radius: 10px;
    }
  
    &-bar1 { left: 0px }
    &-bar2 { left: 0px }
    &-bar3 { left: 0px }
  }
}