.header {

    &-wrapper {
        background-color: var(--background-color-2);
        border-bottom: 2px solid var(--border-color-2);
        color: var(--font-color-2);
    
        &::before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 60px;
            background-color: var(--background-color-2);
            z-index: -1;
            // border-bottom: 2px solid var(--border-color-2);
        }        
    }

    // All Menus
    nav {

        &.open {
            max-height: var(--page-height);
        }
        
        ul li {
            position: relative;

            button, a {
                display: flex;
                align-items: center;
                gap: 5px;

                background-color: transparent;

                &:hover {
                    color: var(--font-active-color-2);
                }

                &:not(:hover) {
                    color: var(--font-color-3);
                }

                svg {
                    margin: auto auto;
                }
            }            
        }
    }

    // Primary Menu
    // .nav-menu {
    //     position: absolute;
    //     top: 100%;
    //     left: 0;
    //     right: 0;
    //     max-height: 0;
    //     overflow: hidden;
    // }

    // Sub Menu
    .dropdown {

        &-nav {
            overflow: hidden;

            max-height: 0;
            transition: max-height 0.3s ease-in-out;

            background-color: var(--dropdown-background-color);

            ul {
                font-weight: 400;
                color: var(--font-color-2);
            }
        }

        &:not(.open) &-toggle:focus {
            color: var(--font-color-3);
        }


        &.open {            
            .dropdown-toggle, & > a {
                color: var(--font-active-color-2);

                svg {
                    // transition: transform 0.3s ease-in-out;
                    transform: rotate(180deg);
                }
            }

            .dropdown-nav {
                z-index: 1;
                max-height: var(--page-height);
            } 
        }
    }

    @media (max-width: 1023px) {

        .nav-menu {
            position: absolute;
            top: var(--header-height);
            left: 0;
            right: 0;
            
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;

            

            & > ul {
                padding: 0px 32px;
                padding-bottom: 32px;

                background-color: var(--dropdown-background-color);
                border-bottom: 2px solid var(--border-color-2);

                max-height: var(--page-height);
                overflow-y: auto;

                li {
                    border-bottom: 1px solid var(--border-color-2);

                    button, a {
                        width: 100%; // fit-content;
                        padding: 12px 8px 4px 8px;

                        svg {
                            margin: auto 0;
                        }
                    }
                }
            }

            .dropdown-nav {
                background-color: var(--background-color-2);
                // color: var(--font-color-2);
                // overflow-y: auto;
                
                ul {
                    // padding-bottom: 12px;

                    li {
                        margin: 0px 32px;
                    }

                    li button, li a {
                        padding: 8px;
                        // padding-left: 32px;
                    }

                    li:last-of-type {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    @media (min-width: 1024px) {
        
        &-wrapper::before { // For border-bottom over scrollbar in desktop when modal is open
            border-bottom: 2px solid var(--border-color-2);
        }

        .nav-menu {
            > ul {
                 > li {
                    .dropdown-toggle, .nav-link {
                        height: 58px;
                        padding: 20px 10px 10px 10px; // 25px 10px 5px 10px;
                    }

                    // Underline transition (Default non-active)
                    .dropdown .dropdown-toggle, .nav-link {

                        &::before, &::after {
                            content: '';
                            position: absolute;
                            height: 2px;
                            background: var(--font-color-2);
                            transition: all 0.3s ease;
                            bottom: 10px;
                        }

                        &::before {
                            left: 5px;
                            width: 0%;
                        }

                        &::after {
                            right: 5px;
                            width: 0%;
                        }

                    }

                    // Underline transition (Active)
                    .dropdown.open .dropdown-toggle, .nav-link:hover, .nav-link:focus {

                        &::before, &::after {
                            width: calc(50% - 5px);
                            background: var(--font-active-color-2);
                        }
                    }
                }
            }
        }

        .dropdown-nav {
            position: absolute;
            top: 58px;
            right: 0;
            width: max-content;

            border-radius: 0 0 10px 10px;


            ul {
                border: 2px solid var(--border-color-2);

                li {
                    button, a {
                        margin: 0 8px;
                        padding: 12px 8px;

                    }

                    &:not(:last-of-type) {
                        button, a {
                            border-bottom: 1px solid var(--border-color-2);
                        }
                    }
                }
            }
        }
    }
}