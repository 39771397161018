.video-container {
    position: relative;
    border-color: var(--border-color-1);

    // max-width: 608px;
    width: 100%;
    height: auto;

    aspect-ratio: 16/9; // 16/9;
    // padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}