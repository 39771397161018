.spinner { //        Interesting!!!!!
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &-circle {
        width: 30px; // 100px;
        height: 30px; // 100px;
        border: 5px solid #D9D9D9;
        border-top-color: var(--font-active-color-1);
        border-radius: 50%;
        animation: rotate 1s linear infinite;
    }

    &-loading {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.2rem;
        font-weight: 600;
    }

    &-message {
        color: #555555;
        text-align: center;
        width: 100%;
    }
}