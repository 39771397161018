.membership-content {

//     section {
//         display: flex;
//         flex-direction: column;
//         gap: 1rem;
//     }

    ul {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

//     li {
//         display: flex;
//         flex-direction: column;
//         gap: 0.5rem;
//     }
}